@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-tag {
  display: flex;
  padding: 5px;
  color: var(--lia-tag-color);
  background-color: var(--lia-tag-bg-color);
  border-radius: var(--lia-tag-border-radius);
  transition: background-color var(--lia-timing-normal) var(--lia-timing-function);
  word-break: break-all;

  &:hover,
  &:focus {
    color: var(--lia-tag-hover-color);
    background-color: var(--lia-tag-bg-hover-color);
    text-decoration: none;
  }
  @include default-focus-box-shadow();
}

.lia-tag-text {
  display: -webkit-box;
  text-transform: var(--lia-tag-text-transform);
  font-size: var(--lia-tag-font-size);
  font-weight: var(--lia-tag-font-weight);
  line-height: 1.09090909;
  letter-spacing: var(--lia-tag-letter-spacing);
  overflow: hidden;
  white-space: normal;

  + .lia-tag-item:before {
    background-color: var(--lia-tag-color);
  }
}

.lia-tag-item {
  color: var(--lia-tag-color);
  font-size: var(--lia-tag-font-size);
  font-weight: var(--lia-tag-font-weight);
  letter-spacing: var(--lia-tag-letter-spacing);
  line-height: 1;
  flex-shrink: 0;
  opacity: 0.8;
}
